<template>
	<el-container style="height: 100%;position: absolute;left: 0;top: 0;bottom: 0;right: 0; overflow: hidden;">
		<div class="left-aside">
			<div class="logo-title">
				<img src="../assets/images/logo.png" alt="">
				<span v-if="!navBar.isCollapse">“三合一”管理系统</span>
				<!-- <span v-if="!navBar.isCollapse">{{$conf.logo}}</span> -->
			</div>
			<!-- 一级菜单侧边布局 -->
			<el-menu :default-active="navBar.active" class="el-menu-vertical-demo" :unique-opened="true"
				background-color="#001529" @select="handleSelect" style="height: calc(100% - 60px)" text-color="#fff"
				:collapse="navBar.isCollapse">
				<template v-for="(item,index) in navBar.list">
					<el-submenu :index="index|numToString" v-if="item.submenu.length > 0">
						<template slot="title">
							<i :class="item.icon"></i>
							<span>{{item.name}}</span>
						</template>
						<template v-for="(two,twoIndex) in item.submenu">
							<el-submenu :index="index+'-'+twoIndex" v-if="two.submenu.length > 0">
								<template slot="title">{{two.name}}</template>
								<el-menu-item :index="index+'-'+twoIndex+'-'+thirdIndex"
									v-for="(third,thirdIndex) in two.submenu">{{third.name}}</el-menu-item>
							</el-submenu>
							<el-menu-item :index="index+'-'+twoIndex" v-else>{{two.name}}</el-menu-item>
						</template>
					</el-submenu>
					<el-menu-item :index="index|numToString" :key="index" v-else>
						<i :class="item.icon"></i>
						<span slot="title">{{item.name}}</span>
					</el-menu-item>
				</template>
			</el-menu>
		</div>
		<el-container class="bg-light">
			<el-header class="d-flex align-items-center" style="background: white;height: 50px;">
				<!-- 面包屑导航 -->
				<div class="d-flex mb-3 bg-white" style="padding: 20px;margin: -20px;height: 100%;">
					<a class="mr-3" style="cursor: pointer;" @click="handleIsCollapse">
						<i :class="navBar.isCollapse ? 'el-icon-s-unfold': 'el-icon-s-fold'"
							style="line-height: 50px;color:#CCCCCC"></i>
					</a>
					<a class="mr-3" style="cursor: pointer;" @click="handleRefresh">
						<i class="el-icon-refresh-right" style="line-height: 50px;color:#CCCCCC"></i>
					</a>
					<el-breadcrumb v-if="navBar.breadCrumbList.length > 0" separator-class="el-icon-arrow-right"
						style="line-height: 50px;">
						<el-breadcrumb-item>
							<i :class="navBar.breadCrumbList[0].icon" style="line-height: 50px;"></i>
							{{navBar.breadCrumbList[0].title}}
						</el-breadcrumb-item>
						<el-breadcrumb-item v-if="navBar.breadCrumbList.length > 1">
							{{navBar.breadCrumbList[1].title}}
						</el-breadcrumb-item>
						<el-breadcrumb-item v-if="navBar.breadCrumbList.length > 2">
							{{navBar.breadCrumbList[2].title}}
						</el-breadcrumb-item>
					</el-breadcrumb>
				</div>

				<!-- 	<div class="h6 mb-0 ml-auto mr-2" @click="renew" style="cursor: pointer;">
					
					{{user.user_name ? user.user_name : ''}}
				</div> -->

				<div class="h6 mb-0 ml-auto mr-3 d-flex align-items-center" style="cursor: pointer;">
					<div class="mr-3 align-items-center">
						<el-select @change="hanledSearch()" remote filterable size="mini" v-model="selectSearch"
							placeholder="搜索...">
							<template v-for="(item,index) in navBar.list">
								<el-option-group v-if="item.submenu.length > 0" :label="item.name">
									<template v-for="(two,twoIndex) in item.submenu">
										<el-option-group v-if="two.submenu.length > 0" :label="two.name">
											<el-option v-for="three in two.submenu" :label="three.name"
												:value="three.index"></el-option>
										</el-option-group>
										<el-option v-else :label="two.name" :value="two.index"></el-option>
									</template>
								</el-option-group>
								<el-option v-else :label="item.name" :value="item.index"></el-option>
							</template>
						</el-select>
					</div>

					<!-- 全屏 -->
					<el-tooltip class="item mr-3" effect="dark" :content="isScreenFull?'退出全屏':'全屏'"
						placement="top-start">
						<i @click="screenFullBtn" style="font-size: 18px;" class="el-icon-full-screen"></i>
						<!-- <img src="../assets/images/screenFull.png" alt="" @click="screenFullBtn" class="screenFull"v-if="!isScreenFull"> -->
						<!-- <img src="../assets/images/exitScreenFull.png" alt="" @click="screenFullBtn" class="screenFull"v-else> -->
					</el-tooltip>

					<!-- 用户操作 -->
					<div class="d-flex align-items-center">
						<el-dropdown trigger="hover" @command="selectCommand" placement="bottom">
							<div style="font-size: 12px;">{{user ? user.user_name : ''}}</div>
							<el-dropdown-menu size="small" slot="dropdown">
								<el-dropdown-item style="font-size: 12px;" command="user"><i
										class="el-icon-user"></i>个人中心</el-dropdown-item>
								<el-dropdown-item style="font-size: 12px;" command="logout"><i
										class="el-icon-circle-close"></i>退出登录</el-dropdown-item>
							</el-dropdown-menu>
						</el-dropdown>
					</div>
				</div>
				<!-- 	<a class="mb-0 ml-4 mr-3" @click="logout" style="cursor: pointer;">
					<i class="el-icon-error" style="line-height: 50px;"></i>退出
				</a> -->
			</el-header>
			<!-- 侧边布局 -->

			<!-- 标签栏 -->
			<tags></tags>

			<el-main style="position: relative;" v-loading="loading">
				<!-- 主内容 -->
				<router-view></router-view>
				<el-backtop target=".el-main" :bottom="100">
					<div
						style="height: 100%; width: 100%; background-color: #f2f5f6; box-shadow: 0 0 6px rgba(0,0,0, .12); text-align: center;  line-height: 40px; color: #1989fa;">
						<i class="el-icon-top"></i>
					</div>
				</el-backtop>
			</el-main>
		</el-container>
		<el-dialog :modal="false" title="您有举报信息未处理,请尽快处理!" :visible.sync="showReportVisible" top="3vh" width="350px">
		</el-dialog>
	</el-container>
</template>

<script>
	import common from "@/common/mixins/common.js"
	import tags from "@/components/tags/tags.vue";
	import screenfull from 'screenfull'
	import {
		mapState
	} from 'vuex'

	export default {
		mixins: [common],
		components: {
			tags
		},
		provide() {
			return {
				layout: this
			}
		},
		data() {
			return {
				showReportVisible: false,
				selectSearch: '',
				loading: false,
				mp3: null,
				fullWidth: document.documentElement.clientWidth,
				isScreenFull: false
			}
		},

		mounted() {
			window.onresize = () => {
				return (() => {
					// 判断是否在全屏
					if (!this.checkFull()) {
						// 退出全屏后要执行的动作
						this.isScreenFull = false;
					}
					window.fullWidth = document.documentElement.clientWidth
					this.fullWidth = window.fullWidth
				})()
			}

		},
		created() {
			this.showReport()
			// 获取面包屑导航
			this.getRouterBran()
		},

		watch: {
			'$route'(to, from) {
				this.setActive(to.name)
				this.getRouterBran()
			},
			/* 监听屏幕宽度*/
			fullWidth(val) {
				if (val < 1080 && val > 0) {
					this.navBar.isCollapse = true
					window.sessionStorage.setItem('navBar', JSON.stringify(this.navBar))
				} else {
					this.navBar.isCollapse = false
					window.sessionStorage.setItem('navBar', JSON.stringify(this.navBar))
				}
			}
		},
		computed: {
			...mapState({
				user: state => state.user.user.user,
				navBar: state => state.menu.navBar
			}),
		},
		methods: {
			showReport() {
				let _this = this;
				_this.mp3 = new Audio();
				_this.mp3.src = _this.$conf.base_url + "/2021070616315660e414fced253.mp3";

				setInterval(function() {
					if (_this.showReportVisible == false) {
						_this.axios({
							token: true,
							params: {
								s: "store/Report/untreated"
							},
							method: 'get',
						}).then(res => {
							if (res.data.data > 0) {
								_this.mp3.play();

								_this.$message({
									message: "您有举报信息未处理,请尽快处理!",
									type: 'success'
								});
							}
						}).catch(err => {})
					}
				}, 20000);
			},
			hanledSearch() {
				this.$router.push({
					name: this.selectSearch
				})
			},
			// 是否全屏
			screenFullBtn() {
				screenfull.toggle()
				this.isScreenFull = !this.isScreenFull
			},

			//检测是否在全屏
			checkFull() {
				//判断浏览器是否处于全屏状态 （需要考虑兼容问题）
				//火狐浏览器
				var isFull = document.mozFullScreen ||
					document.fullScreen ||
					//谷歌浏览器及Webkit内核浏览器
					document.webkitIsFullScreen ||
					document.webkitRequestFullScreen ||
					document.mozRequestFullScreen ||
					document.msFullscreenEnabled
				if (isFull === undefined) {
					isFull = false
				}
				return isFull;

			},

			// 显示loading
			showLoading() {
				this.loading = true
			},
			// 隐藏loading
			hideLoading() {
				this.loading = false
			},
			getRouterBran() {
				// 更新面包屑
				let active = this.navBar.active
				let breadCrumbList = []
				if (active != '') {
					let nav = active.split("-");
					let navLen = nav.length;
					if (navLen > 0) {
						breadCrumbList.push({
							title: this.navBar.list[nav[0]].name,
							icon: this.navBar.list[nav[0]].icon,
							path: this.navBar.list[nav[0]].index
						});
						if (navLen > 1) {
							breadCrumbList.push({
								title: this.navBar.list[nav[0]].submenu[nav[1]].name,
								path: this.navBar.list[nav[0]].submenu[nav[1]].index
							});
							if (navLen > 2) {
								breadCrumbList.push({
									title: this.navBar.list[nav[0]].submenu[nav[1]].submenu[nav[2]].name,
									path: this.navBar.list[nav[0]].submenu[nav[1]].submenu[nav[2]].index
								});
							}
						}
					}
				}
				this.navBar.breadCrumbList = breadCrumbList;
				window.sessionStorage.setItem('navBar', JSON.stringify(this.navBar))
			},
			setActive(name) {
				let active = '';
				for (let i = 0; i < this.navBar.list.length; i++) {
					let two = this.navBar.list[i].submenu;
					if (name == this.navBar.list[i].index) {
						active = i + '';
						if (two.length > 0) {
							for (let t = 0; t < two.length; t++) {
								if (two[t].index == name) {
									active += '-' + t;
									let three = two[t].submenu;
									if (three.length > 0) {
										for (let r = 0; r < three.length; r++) {
											if (three[r].index == name) {
												active += '-' + r;
											}
										}
									}
								}
							}
						}
					} else {
						// 判断二级是否存在
						if (two.length > 0) {
							for (let t = 0; t < two.length; t++) {
								let three = two[t].submenu;
								if (two[t].index == name) {
									active += i + '-' + t;
									if (three.length > 0) {
										for (let r = 0; r < three.length; r++) {
											if (three[r].index == name) {
												active += '-' + r;
											}
										}
									}
								} else {
									if (three.length > 0) {
										for (let r = 0; r < three.length; r++) {
											if (three[r].index == name) {
												active += i + '-' + t + '-' + r;
											}
										}
									}
								}
							}
						}
					}
				}
				this.navBar.active = active
				window.sessionStorage.setItem('navBar', JSON.stringify(this.navBar))
			},
			handleSelect(key, keyPath) {
				this.navBar.active = key
				window.sessionStorage.setItem('navBar', JSON.stringify(this.navBar))

				let nav = key.split("-");
				let navLen = nav.length;

				// // 默认选中跳转到当前激活
				if (navLen > 0) {
					let name;
					if (navLen == 1) {
						name = this.navBar.list[nav[0]].index
					} else if (navLen == 2) {
						name = this.navBar.list[nav[0]].submenu[nav[1]].index
					} else if (navLen == 3) {
						name = this.navBar.list[nav[0]].submenu[nav[1]].submenu[nav[2]].index
					}
					this.$router.push({
						name: name
					})
				}
			},
			handleIsCollapse() {
				let isCollapse = !this.navBar.isCollapse
				this.navBar.isCollapse = isCollapse;
				window.sessionStorage.setItem('navBar', JSON.stringify(this.navBar))
			},
			handleRefresh() {
				this.$router.go(0)
			},


			//顶部下拉菜单选择操作
			selectCommand(val) {
				if (val === 'user') {
					this.$router.push({
						name: "store.user/renew"
					})
				} else if (val === 'logout') {
					this.logout()
				}

			},

			// 退出登录
			logout() {
				this.axios({
					token: true,
					params: {
						s: 'store/passport/logout',
					},
					method: 'post',
				}).then(res => {
					this.$message('退出成功')
					// 清除状态和存储
					this.$store.commit('logout')
					// 返回到登录页
					this.$router.push({
						name: "passport/login"
					})
				}).catch(err => {
					// 清除状态和存储
					this.$store.commit('logout')
					// 返回到登录页
					this.$router.push({
						name: "login"
					})
				})
			},
		}
	}
</script>

<style>
	.el-menu-vertical-demo:not(.el-menu--collapse) {
		width: 200px;
		min-height: 400px;
	}

	.left-aside {
		box-sizing: border-box;
		max-width: 200px;
		min-width: 64px;
		height: 100vh;
		overflow-y: auto;
		overflow-x: hidden;
	}

	.left-aside .logo-title {
		padding-left: 5px;
		padding-right: 5px;
		color: #FFFFFF;
		height: 60px;
		font-weight: 600;
		text-align: center;
		background-color: #001529;
		line-height: 60px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.logo-title img {
		width: 1.875rem;
		height: 1.875rem;

	}

	.logo-title span {
		margin-left: 0.625rem;
	}



	.left-aside .el-menu {
		border-right: none;
		list-style: none;
		position: relative;
		margin: 0;
		padding-left: 0;
		background-color: #FFFFFF;
	}

	.left-aside .el-submenu .el-menu-item {
		height: 50px;
		line-height: 50px;
		padding: 0 45px;
		min-width: 0px;
	}

	.left-aside .el-menu-item.is-active {
		color: #FFFFFF;
		background-color: #2d8cf0 !important;
	}

	.screenFull {
		width: 25px;
		height: 25px;
		margin-right: 1.875rem;
	}


	.el-container {
		display: flex;
		flex-direction: row;
		flex: 1;
		/* flex-basis: auto; */
		box-sizing: border-box;
		min-width: 0;
	}

	.el-breadcrumb>span:last-child>.el-breadcrumb__inner {
		font-weight: 700;
		color: #515a6e;
	}
</style>
